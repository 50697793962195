import PropTypes from "prop-types";
import * as React from "react";
import Box from "../../../assets/svg/checkbox/box.svg";
import Check from "../../../assets/svg/checkbox/check.svg";
import * as checkboxStyles from "./checkbox.module.css";
import * as groupStyles from "./checkbox-group.module.css";
import * as fieldStyles from "./field.module.css";

const CheckboxGroup = ({register, label, name, required, error, options, disabled}) => {
    return (
        <div className={groupStyles.checkboxGroup}>
            {label && <p className={groupStyles.checkboxGroup__label}>{label}</p>}
            {options.map(({value, label: optionLabel}, idx) => {
                return (
                    <label className={`${checkboxStyles.checkbox} ${groupStyles.checkboxGroup__checkbox}`} key={idx}>
                        <input type="checkbox" value={value} name={name} {...register(name, {required, disabled})} />
                        <span className={checkboxStyles.checkbox__icon}>
                            <Box className={checkboxStyles.checkbox__box} />
                            <Check className={checkboxStyles.checkbox__check} />
                        </span>
                        <span className={checkboxStyles.checkbox__label}>{optionLabel}</span>
                    </label>
                );
            })}
            {error && (
                <p className={fieldStyles.field__error}>
                    {error?.message || `Поле${label ? ` ${label}` : ""} обязательно к заполнению`}
                </p>
            )}
        </div>
    );
};

CheckboxGroup.defaultProps = {
    required: false,
    label: null,
    error: null,
    disabled: false,
};

CheckboxGroup.propTypes = {
    register: PropTypes.func.isRequired,
    label: PropTypes.node,
    name: PropTypes.string.isRequired,
    required: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.exact({
            value: PropTypes.bool,
            message: PropTypes.string,
        }),
    ]),
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.shape({message: PropTypes.string}), PropTypes.string]),
    options: PropTypes.arrayOf(
        PropTypes.exact({value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), label: PropTypes.string}),
    ).isRequired,
};

export default CheckboxGroup;
