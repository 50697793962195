import PropTypes from "prop-types";
import * as React from "react";

const TEMPLATE_METHODS_ORDER = ["link", "color", "bold", "italic", "newLine", "nbsp"];

const TEMPLATE_FRAGMENT_REGEX = /(<%=.*?%>)/g;
const TEMPLATE_FRAGMENT_EXPRESSION_REGEX = /<%=(.*?)%>/;
const EXPRESSION_PARTS_REGEX = /(?<name>\w+)(?:\((?<args>.+?)\))?/;
const EXPRESSION_ARGUMENTS_SPLIT_REGEX = /,\s*/;

const prepareMethodsData = (methods) => {
    return methods.reduce((acc, method) => {
        const {
            groups: {name, args},
        } = EXPRESSION_PARTS_REGEX.exec(method);

        const methodData = {};

        if (args) {
            methodData.args = args.split(EXPRESSION_ARGUMENTS_SPLIT_REGEX);
        }

        if (!acc[name]) {
            acc[name] = [];
        }

        acc[name].push(methodData);

        return acc;
    }, {});
};

const methodComponents = {
    link: ({content, args}) => {
        const [url] = args;

        return (
            <a href={url} target="_blank" rel="noreferrer">
                {content}
            </a>
        );
    },
    color: ({content, args}) => {
        const [color] = args;

        return <span style={{color}}>{content}</span>;
    },
    bold: ({content}) => {
        return <strong>{content}</strong>;
    },
    italic: ({content}) => {
        return <em>{content}</em>;
    },
    newLine: ({content}) => {
        return (
            <>
                {content}
                <br />
            </>
        );
    },
    nbsp: ({content}) => {
        return (
            <>
                {content}
                &nbsp;
            </>
        );
    },
};

const TemplateString = ({template}) => {
    const templateParts = template.split(TEMPLATE_FRAGMENT_REGEX).map((part) => {
        if (!TEMPLATE_FRAGMENT_REGEX.test(part)) {
            return part;
        }

        try {
            const templateExpression = TEMPLATE_FRAGMENT_EXPRESSION_REGEX.exec(part)[1];
            const [content, ...rawMethods] = templateExpression.trim().split("|");

            const methods = prepareMethodsData(rawMethods);

            return TEMPLATE_METHODS_ORDER.reduce((acc, methodName) => {
                if (!methods[methodName]) {
                    return acc;
                }

                return methods[methodName].reduce((childAcc, methodData) => {
                    const Component = methodComponents[methodName];

                    return <Component content={childAcc} {...methodData} key={part} />;
                }, acc);
            }, content);
        } catch (e) {
            console.error(e);

            return part;
        }
    });

    return React.createElement(React.Fragment, null, templateParts);
};

TemplateString.propTypes = {
    template: PropTypes.string.isRequired,
};

export default TemplateString;
