import {StaticImage} from "gatsby-plugin-image";
import PropTypes from "prop-types";
import * as React from "react";
import {useCallback, useMemo, useRef, useState} from "react";
import Logo from "../../assets/svg/logo.svg";
import Section from "../Layout/Section";
import CheckboxGroup from "./Fields/CheckboxGroup";
import CheckboxField from "./Fields/Checkbox";
import Select from "./Fields/Select";
import Input from "./Fields/Input";
import StatusModal from "./StatusModal";
import Form from "./index";
import TemplateString from "../Common/TemplateString";
import Link from "../UI/Link";
import * as freeDemoFormStyles from "./free-demo.module.css";

const DynamicForm = ({withBackground, formDescription, title, buttonText, fields}) => {
    const formSection = useRef();
    const [showStatusModal, setStatusModal] = useState(false);
    const [formStatus, setFormStatus] = useState("");

    const formFields = useMemo(() => {
        const fieldsList = (fields || []).map(({label, type, options}) => {
            return {
                name: label,
                render({control, register, error, key, getValues, setValue}) {
                    if (type === "select") {
                        return (
                            <Select
                                getValues={getValues}
                                setValue={setValue}
                                placeholder={label}
                                register={register}
                                name={label}
                                key={key}
                                error={error}
                                options={options.map(({value}) => ({value, label: value}))}
                            />
                        );
                    }

                    if (type === "checkbox") {
                        return (
                            <CheckboxGroup
                                label={label}
                                register={register}
                                name={label}
                                key={key}
                                error={error}
                                options={options.map(({value}) => ({value, label: value}))}
                            />
                        );
                    }

                    const rules = {};

                    if (type === "email") {
                        rules.pattern = {
                            value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
                            message: "Введен некорректный email адрес",
                        };
                    }

                    if (type === "phone") {
                        rules.pattern = {
                            value: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}/i,
                            message: "Номер телефона должен соответствовать шаблону +7 (123) 456-78-90",
                        };
                    }

                    return (
                        <Input
                            register={register}
                            control={control}
                            name={label}
                            placeholder={label}
                            required="Поле обязательно к заполнению"
                            error={error}
                            key={key}
                            type={type === "phone" ? "tel" : type}
                            rules={rules}
                        />
                    );
                },
            };
        });

        fieldsList.push({
            name: "privacy",
            render({register, error, key}) {
                const label = (
                    <span>
                        Я принимаю условия{" "}
                        <Link to="/privacy" withArrow={false}>
                            Политики в отношении обработки персональных данных
                        </Link>
                    </span>
                );

                return (
                    <CheckboxField
                        register={register}
                        label={label}
                        name="privacy"
                        required="Согласие с политикой в отношении обработки персональных данных обязательно"
                        error={error}
                        key={key}
                    />
                );
            },
        });

        return fieldsList;
    }, [fields]);

    const closeStatusModal = () => {
        setStatusModal(false);
    };

    const scrollToForm = useCallback(() => {
        if (!formSection.current) {
            return;
        }

        formSection.current.scrollIntoView({alignToTop: true, behavior: "smooth"});
    }, [formSection]);

    const handler = useCallback(
        async (data) => {
            const requestData = {
                title,
                department: "sales",
                fields: [
                    {
                        label: "Страница",
                        value: window.location.href,
                    },
                    ...Object.entries(data)
                        .map(([key, value]) => {
                            if (key === "privacy") {
                                return undefined;
                            }

                            return {
                                label: key,
                                value,
                            };
                        })
                        .filter(Boolean),
                ],
            };
            const response = await fetch(`${process.env.GATSBY_API_URL}/mailer/send`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify(requestData),
            });
            const json = await response.json();

            setStatusModal(true);
            scrollToForm();
            if (!json.success) {
                console.error("Error");
                setFormStatus("error");

                return;
            }

            setFormStatus("success");
        },
        [scrollToForm, title],
    );

    return (
        <Section ref={formSection} containerClassName={freeDemoFormStyles.freeDemoForm__container}>
            {!showStatusModal && (
                <div
                    className={
                        withBackground
                            ? freeDemoFormStyles.freeDemoForm
                            : `${freeDemoFormStyles.freeDemoForm} ${freeDemoFormStyles.freeDemoForm_noBackground}`
                    }
                >
                    <div className={freeDemoFormStyles.freeDemoForm__info}>
                        <div>
                            {!!formDescription.kicker && (
                                <span className={freeDemoFormStyles.freeDemoForm__kicker}>
                                    {formDescription.kicker}
                                </span>
                            )}{" "}
                            {!!formDescription.title && (
                                <h2 className={freeDemoFormStyles.freeDemoForm__title}>
                                    <TemplateString template={formDescription.title} />
                                </h2>
                            )}{" "}
                            {!!formDescription.description && (
                                <p className={freeDemoFormStyles.freeDemoForm__desc}>{formDescription.description}</p>
                            )}
                        </div>
                        <Logo className={freeDemoFormStyles.freeDemoForm__logo} />
                    </div>
                    <div className={freeDemoFormStyles.freeDemoForm__formWrapper}>
                        {title && <h4 className={freeDemoFormStyles.freeDemoForm__formTitle}>{title}</h4>}{" "}
                        <Form onSubmit={handler} fields={formFields} buttonText={buttonText} />
                    </div>
                    {withBackground && (
                        <div>
                            <StaticImage
                                src="../../assets/images/form/free-demo/desktop.png"
                                alt=""
                                placeholder="none"
                                className={`${freeDemoFormStyles.freeDemoForm__gradient} ${freeDemoFormStyles.freeDemoForm__gradient_desktop}`}
                                style={{filter: "blur(70px)"}}
                            />
                            <StaticImage
                                src="../../assets/images/form/free-demo/tablet.png"
                                alt=""
                                placeholder="none"
                                className={`${freeDemoFormStyles.freeDemoForm__gradient} ${freeDemoFormStyles.freeDemoForm__gradient_tablet}`}
                                style={{filter: "blur(70px)"}}
                            />
                            <StaticImage
                                src="../../assets/images/form/free-demo/mobile.png"
                                alt=""
                                placeholder="none"
                                className={`${freeDemoFormStyles.freeDemoForm__gradient} ${freeDemoFormStyles.freeDemoForm__gradient_mobile}`}
                                style={{filter: "blur(70px)"}}
                            />
                        </div>
                    )}
                </div>
            )}{" "}
            {showStatusModal && (
                <StatusModal
                    onClose={closeStatusModal}
                    status={formStatus}
                    className={freeDemoFormStyles.freeDemoForm__statusModal}
                />
            )}
        </Section>
    );
};

DynamicForm.defaultProps = {
    formDescription: {},
    title: "",
    buttonText: "",
    fields: [],
};

DynamicForm.propTypes = {
    withBackground: PropTypes.bool.isRequired,
    formDescription: PropTypes.shape({
        kicker: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }),
    title: PropTypes.string,
    buttonText: PropTypes.string,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string,
            label: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.exact({id: PropTypes.number, value: PropTypes.string})),
        }),
    ),
};

export default DynamicForm;
